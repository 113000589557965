import { useTitle } from 'react-use';

import { GameCenterContextProvider } from '../components/Game/GameCenter';
import { CustomGamePackList } from '../components/Game/UGC';
import { PublicHomeLayout } from '../components/PublicHome/Layout';
import { GamePackContextProvider } from '../pages/GamePack/Context';
import { GamePackCRUDUtility } from '../pages/GamePack/CRUDUtility';
import { makeTitle } from '../utils/common';

function Layout(props: { children?: React.ReactNode }) {
  return (
    <PublicHomeLayout>
      <GameCenterContextProvider>
        <GamePackContextProvider
          embed={false}
          mode='public-home'
          pageType='public'
          routePrefix=''
          editRoutePrefix=''
        >
          {props.children}
          <GamePackCRUDUtility />
        </GamePackContextProvider>
      </GameCenterContextProvider>
    </PublicHomeLayout>
  );
}

export function Component() {
  useTitle(makeTitle('My Custom Games'));

  return (
    <Layout>
      <CustomGamePackList />
    </Layout>
  );
}
